export default {
  data() {
    return {
      articles: [],
    }
  },
  async mounted() {
    this.articles = await this.$axios.$get(`${window.location.origin}/api/blog-latest-articles`)
  },
}
